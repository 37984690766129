import React, { useState, useEffect, useRef } from "react";
import ReactMarkdown from "react-markdown"; // Import the ReactMarkdown library

const WebSocketChat = () => {
  const [input, setInput] = useState(""); // User input
  const [messages, setMessages] = useState([]); // List of all messages
  const ws = useRef(null); // WebSocket reference
  const chatContainerRef = useRef(null); // Ref for auto-scrolling

  // Initialize WebSocket connection
  useEffect(() => {
    ws.current = new WebSocket(`${process.env.REACT_APP_API_WSS}/ws/langchain/`);

    ws.current.onmessage = (event) => {
      const data = JSON.parse(event.data);

      if (data.message) {
        // Append the response to the chat
        setMessages((prevMessages) => [
          ...prevMessages,
          { content: `Agent: ${data.message}`, isStreaming: !data.isFinal },
        ]);
      }
    };

    ws.current.onopen = () => {
      console.log("WebSocket connected.");
    };

    ws.current.onerror = (error) => {
      console.error("WebSocket error:", error);
    };

    ws.current.onclose = () => {
      console.log("WebSocket disconnected.");
    };

    return () => {
      if (ws.current) {
        ws.current.close();
      }
    };
  }, []);

  // Auto-scroll to the latest message
  useEffect(() => {
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTop =
        chatContainerRef.current.scrollHeight;
    }
  }, [messages]);

  // Handle input form submission
  const handleSubmit = (e) => {
    e.preventDefault();
    if (input.trim()) {
      // Send user input to the WebSocket
      ws.current.send(JSON.stringify({ prompt: input }));

      // Add user message to the message list
      setMessages((prevMessages) => [
        ...prevMessages,
        { content: `You: ${input}`, sender: "User" },
      ]);
      setInput(""); // Clear input field
    }
  };

  return (
    <div className="chat-container">
      <div className="chat-messages" ref={chatContainerRef}>
        {messages.map((msg, index) => (
          <div
            key={index}
            className={`message ${msg.sender === "User" ? "user-message" : "ai-message"
              }`}
          >
            <ReactMarkdown>{msg.content}</ReactMarkdown>
          </div>
        ))}
      </div>
      <form className="chat-input" onSubmit={handleSubmit}>
        <input
          type="text"
          value={input}
          onChange={(e) => setInput(e.target.value)}
          placeholder="Type your message..."
        />
        <button type="submit">Send</button>
      </form>
    </div>
  );
};

export default WebSocketChat;
